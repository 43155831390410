<template>
  <div>
    <div id="giochi" v-html="getOrganization.data.block4">
      <!-- HTML/block4 -->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
name: "giochi",
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "getOrganization"
    ])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MAINMENU.GIOCHI") }]);
  },
  methods: {}
}
</script>

<style scoped>

</style>
